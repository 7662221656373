import { g as getElement } from "./index-bc9b1baf.js";
import { q as findNodeInEventPath } from "./MutationObserverSafe-8cdfc100.js";
import { i as isSSR, j as SUBSCRIPTIONS_KEY, g as getDocument } from "./element-d66cb8c2.js";
import { a as SubscriptionList } from "./subscription-list.class-20a90866.js";
var DEFAULT_TRIGGER_EVENT = "click";
var CLICK_OUTSIDE_ENABLE_SUBJECT_KEY = "click-outside-enable-subject";
function isExcluded(t, i) {
  if (t && i) {
    for (var r = 0, n = i; r < n.length; r++) {
      var e = n[r];
      if (e.contains(t)) {
        return true;
      }
    }
  }
  return false;
}
function getTriggerEvents(t) {
  return (t.triggerEvents || DEFAULT_TRIGGER_EVENT).split(",").map(function (t) {
    return t.trim();
  });
}
function getExcludedNodes(t) {
  var i;
  var r = ["owc-virtual-keyboard", t.exclude].filter(Boolean).join(", ");
  try {
    return Array.from(((i = getDocument()) === null || i === void 0 ? void 0 : i.querySelectorAll(r)) || []);
  } catch (t) {
    return [];
  }
}
function ClickOutside(t) {
  return function (i, r) {
    var n = i.componentDidLoad,
      e = i.disconnectedCallback,
      o = i.connectedCallback;
    var s = ["co", r].join("-");
    function u(i) {
      if (!isSSR()) {
        for (var r = 0, n = getTriggerEvents(t); r < n.length; r++) {
          var e = n[r];
          window.removeEventListener(e, i[s], true);
        }
      }
    }
    function a(i) {
      u(i);
      if (!isSSR()) {
        for (var r = 0, n = getTriggerEvents(t); r < n.length; r++) {
          var e = n[r];
          window.addEventListener(e, i[s], true);
        }
      }
    }
    function c(i) {
      var n = getElement(i);
      var e = i[r];
      !(n[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (n[SUBSCRIPTIONS_KEY] = new SubscriptionList());
      var o = n[SUBSCRIPTIONS_KEY];
      i[s] = function (r) {
        var o = r.target;
        var s = findNodeInEventPath(r, n);
        var u = getExcludedNodes(t);
        if (!isExcluded(o, u) && !s) {
          e.call(i, r);
        }
      };
      o[CLICK_OUTSIDE_ENABLE_SUBJECT_KEY] = t.enabled$(i).subscribe(function (t) {
        t ? a(i) : u(i);
      });
    }
    i.componentDidLoad = function () {
      c(this);
      return n && n.call(this);
    };
    i.connectedCallback = function () {
      c(this);
      return o && o.call(this);
    };
    i.disconnectedCallback = function () {
      var t = getElement(this);
      var i = t === null || t === void 0 ? void 0 : t[SUBSCRIPTIONS_KEY];
      i && i instanceof SubscriptionList && i.unsubscribeSafe(CLICK_OUTSIDE_ENABLE_SUBJECT_KEY);
      u(this);
      return e && e.call(this);
    };
  };
}
export { ClickOutside as C };